import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { GetAllBikers } from '../../redux/actions/Bikers';
import { useDispatch, useSelector } from 'react-redux';
import { AssignBiker, AvailabelBikersAssign, GetAllOrdersAdmin, GetSpesificOrder } from '../../redux/actions/OrdersAdmin';
import { Dropdown } from 'react-bootstrap';


function NewByckerModal({ orderId, slotID, orderDate, bikerName, pagePagination, pageCount, sort, status, ordersCount, searchKey, workingArea }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectBiker, setBiker] = useState('');
    const [loading, setLoading] = useState(false);




    // if (show) {
    //     console.log(orderDate);
    //     console.log(slotID);
    //     console.log(bikerName);
    // }

    const dispatch = useDispatch();
    // var today = new Date();
    // var year = today.getFullYear();
    // var month = today.getMonth() + 1;
    // var day = today.getDate();
    // var formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;

    useEffect(() => {
        if (show) {
            dispatch(AvailabelBikersAssign(
                {
                    date: orderDate,
                    slotId: slotID,
                    order_id: orderId
                }
            ));
        }
    }, [dispatch, show])



    const AvailableBikers = useSelector(state => state.OrdersAdminReducer.availBikers);
    const assignBiker = async () => {
        try {
            setLoading(false)
            await dispatch(AssignBiker(
                {
                    order_id: orderId,
                    biker_id: selectBiker
                }
            ))
            if (searchKey === "") {
                await dispatch(GetAllOrdersAdmin({
                    pageCount: pageCount,
                    sort: sort,
                    status: status,
                    ordersCount: parseInt(ordersCount)
                }));
            } else {
                await dispatch(GetSpesificOrder({
                    searchValue: searchKey,
                    sort,
                    pageCount: pageCount,
                    ordersCount: parseInt(ordersCount)
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true);
            handleClose();
            setShowSecondModal(false);
            dispatch(AvailabelBikersAssign(
                {
                    date: orderDate,
                    slotId: slotID,
                    order_id: orderId
                }
            ));
        }
    }


    const update = useSelector(state => state.OrdersAdminReducer.assignBiker);



    //Second Modal
    const [showSecondModal, setShowSecondModal] = useState(false);

    const handleOpenSecondModal = (e) => {
        setBiker(e.target.value)
        setShowSecondModal(true);
        handleClose();
    }


    console.log(AvailableBikers)



    return (
        <>
            <button onClick={handleShow} style={{ color: "#A85923", background: "#A8592333" }} className='btn'> اعادة تعيين البايكر</button>

            <Modal show={show} onHide={handleClose} className='font' centered dir="rtl">
                <Modal.Header>
                    <Modal.Title>اعادة تعيين بايكر جديد</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>حدد اسم البايكر المراد تعيينه</span>
                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" style={{ background: "#EFF1F9" }} onChange={handleOpenSecondModal}>
                            <option value="" hidden>حدد البايكر المراد اضافته</option>
                            {
                                AvailableBikers?.length !== 0 ? (
                                    AvailableBikers?.map((availableBiker) => {
                                        if (true) {
                                            return (
                                                <option key={availableBiker.user_id} value={availableBiker.id} onClick={handleOpenSecondModal}>
                                                    {availableBiker.user.first_name} - {availableBiker.is_available ? (<span style={{ color: "green" }}>(No Orders)</span>) : (<span style={{ color: "red" }}>(Has Orders)</span>)}
                                                </option>
                                            )
                                        } else {
                                            return null; // إذا كان البايكر نفس الحالي، عدم إظهاره
                                        }
                                    })
                                ) : (
                                    <option>No available bikers for this working area at this time </option>
                                )
                            }
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تعيين
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Second Modal */}
            <Modal show={showSecondModal} onHide={() => setShowSecondModal(false)} className='font py-4 ' centered dir="rtl">
                <p>هل انت متاكد من تعيين هذا البايكر</p>
                <Modal.Footer className='m-auto w-100'>
                    <button className='btn' onClick={assignBiker} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تاكيد تعيين
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={() => setShowSecondModal(false)}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default NewByckerModal;

