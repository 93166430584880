import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { GetAllOrdersAdmin, GetSpesificOrder, UpdateSchudle } from '../../redux/actions/OrdersAdmin';
import { GetAvailableSlots } from '../../redux/actions/Slots';
import Form from 'react-bootstrap/Form';
import { format } from 'date-fns';

function PastBooking({ orderId, slotId, pagePagination, pageCount, sort, status, ordersCount, searchKey, workingArea }) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectData, setSelectData] = useState('');
    const [slotIddata, setSlotID] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSelectChange = (event) => {
        setSelectData(event.target.value);
    };

    const dispatch = useDispatch();


    // console.log(searchKey);

    const UpdateSchudledata = async () => {
        setLoading(true)
        try {
            await dispatch(UpdateSchudle({
                order_id: orderId,
                order_date: selectData,
                slot_id: slotIddata,
            }))
        } catch (e) {

        } finally {
            setLoading(false);
            setSelectData('');
            if (searchKey === "") {
                await dispatch(GetAllOrdersAdmin({
                    pageCount: pageCount,
                    sort: sort,
                    status: status,
                    ordersCount: parseInt(ordersCount)
                }));
            } else {
                await dispatch(GetSpesificOrder({
                    searchValue: searchKey,
                    sort,
                    pageCount: pageCount,
                    ordersCount: parseInt(ordersCount)
                }))
            }
            handleClose();
        }
        setLoading(false)
    }


    useEffect(() => {
        dispatch(GetAvailableSlots({
            date: selectData,
            working_area_id: workingArea
        }))
    }, [selectData])

    const dataRes = useSelector(state => state.GetSlots.Get_Slots);

    const currentDate = new Date();
    const formattedDate = format(currentDate, 'yyyy-MM-dd');

    // console.log(formattedDate); // ستطبع "2024-02-15"

    return (
        <>
            <button onClick={handleShow} style={{ color: "#F2994A", background: "#F2994A33" }} className='btn'>جدولة الحجز</button>

            <Modal show={show} onHide={handleClose} dir="rtl" centered className='font'>
                <Modal.Header>
                    <Modal.Title>جدولة حجز مسبق</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>حدد التاريخ والتوقيت المراد الجدوله له</span>
                    <div className='mt-3'>
                        <input type="date" onChange={handleSelectChange} className='w-100' min={formattedDate} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <Form.Select aria-label="" style={{ background: "#EFF1F9" }} onChange={(e) => setSlotID(e.target.value)}>
                            <option hidden>تحديد الوقت</option>
                            {dataRes ? (
                                dataRes.map((slotAvailable) => (
                                    slotAvailable.is_active ? (
                                        <option key={slotAvailable.id} value={slotAvailable.id}>
                                            {slotAvailable.name}
                                        </option>
                                    ) : null
                                ))
                            ) : (null)}
                        </Form.Select>
                    </div>
                    <div className='mt-3'>
                        <input type="text" placeholder='سبب الجدوله' className='w-100' min="2005-01-01" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={UpdateSchudledata}>
                        جدوله
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PastBooking;