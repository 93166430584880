import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Search from './Search';
import NewByckerModal from '../../Modals/NewByckerModal';
import PastBooking from '../../Modals/PastBooking';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { GetAllOrdersAdmin, GetAllOrdersAdminSort, GetAllOrdersAdminStatus, GetSpesificOrder, OrderStatus } from '../../../redux/actions/OrdersAdmin';
import Pagination from '../../../Utils/Pagination';
import CancelOrder from '../../Modals/CancelOrder';
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
const BookingMenu = () => {
  //Get Color Of Status
  const getStatusColor = (status) => {
    switch (status) {
      case "CREATED":
        return '#828282';
      case "CANCELLED":
        return '#EB5757';
      case "COMPLETED":
        return 'rgb(60, 196, 65)';
      case "BIKER_ON_THE_WAY":
        return '#AD6423';
      case "BIKER_ARRIVED":
        return '#6D5743';
      case "STARTED":
        return '#E87610';
      default:
        return '#000000';
    }
  };







  const [currentPage, setPagePag] = useState(1);
  const [counts, setCounts] = useState('');
  const [sort, setSort] = useState('DESC');
  const [statusData, setStatusData] = useState("");
  const [ordersData, setOrdersData] = useState([]);

  //Get All Orders

  const dispatch = useDispatch();



  const fetchAllOrders = async (page, sort) => {
    if (orders) {
      await dispatch(GetAllOrdersAdmin({
        pageCount: page,
        sort: sort,
        status: statusData,
        ordersCount: counts,
      }))
    }
  }

  useEffect(() => {
    fetchAllOrders(currentPage, sort, statusData);
  }, [currentPage, sort, dispatch, statusData])



  const orders = useSelector(state => state.OrdersAdminReducer.OrdersAdmin);


  useEffect(() => {
    if (orders && orders.orders) {
      setOrdersData(orders.orders);
      setCounts(orders.ordersCount)
    }
  }, [orders])





  const getPage = async (page) => {
    setPagePag(page)
  };



  //Get Search
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = async () => {
    await dispatch(GetSpesificOrder({
      searchValue,
      sort,
      pageCount: currentPage,
      ordersCount: counts
    }))

  };



  useEffect(() => {
    dispatch(OrderStatus());
  }, [dispatch])

  const ordersStatusData = useSelector(state => state.OrdersAdminReducer.OrdersStatus)


  const handleChildData = (data) => {
    console.log('Data received from child:', data);
    // يمكنك هنا استخدام البيانات المستلمة بالطريقة التي تريدها في الأب
  };



  return (
    <div>
      <div>
        <div className='flexItem justify-content-start mt-4'>
          <div className='mx-3 search-container'>
            <input
              type="search"
              className='form-control search-input'
              name="search"
              id="search"
              placeholder='ابحث عن.....'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <FaSearch className="search-icon" onClick={handleSearch} />
          </div>
          <div className='mx-3'>
            <Form.Select aria-label="Default select example" value={sort} onChange={(e) => setSort(e.target.value)}>
              <option hidden>تاريخ الحجز: تنازلي</option>
              <option value="ASC">تصاعدي</option>
              <option value="DESC">تنازلي</option>
            </Form.Select>
          </div>
          <div className='mx-3'>
            <Form.Select aria-label="Default select example" onChange={(e) => setStatusData(e.target.value)}>
              <option hidden>حالة الحجز	</option>
              <option value="">الكل</option>
              {
                ordersStatusData ? (
                  ordersStatusData.map((orderData, index) => {
                    return (
                      <option key={index + 1} value={orderData.value}>{orderData.name}</option>
                    )
                  })
                ) : (null)
              }
            </Form.Select>
          </div>
        </div>
      </div>



      <div className='mt-5'>
        <Table bordered style={{ borderRadius: "20px" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>رقم الحجز</th>
              <th>اسم العميل</th>
              <th>تليفون العميل</th>
              <th>تاريخ الحجز</th>
              <th>التوقيت</th>
              <th>اسم البايكر</th>
              <th>حالة الحجز</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody style={{ color: " #4F4F4F" }}>
            {
              ordersData ? (
                ordersData.map((order, index) => {
                  // console.log(order)
                  return (
                    <tr className='mb-1' key={index}>
                      <td className="py-3">{index + 1}</td>
                      <td className="py-3">{order.number}</td>
                      {
                        order.customer && order.customer.user ? (
                          <td className="py-3">{order.customer.user.first_name} {order.customer.user.last_name}</td>
                        ) : null
                      }
                      {
                        order.customer && order.customer.user ? (
                          <td className="py-3">{order.customer.user.phone}</td>
                        ) : null
                      }
                      <td className="py-3">{order.order_date}</td>
                      <td className="py-3">
                        {
                          order.slot ? (
                            <td>{order.slot.name}</td>
                          ) : null
                        }
                      </td>
                      <td className="py-3">
                        {
                          order.biker !== null ? (
                            <td>{order.biker.user.first_name}</td>
                          ) : <td>لايوجد بايكر</td>
                        }
                      </td>
                      <td className="py-3">
                        <span style={{ color: getStatusColor(order.status) }}>{order.status}</span>
                      </td>
                      <td>
                        {
                          order.biker !== null ? (
                            order.status !== "COMPLETED" && order.status !== "CANCELLED" && order.status !== "BIKER_ARRIVED" && order.deleted_at === null ? (
                              <td><NewByckerModal orderId={order.id} slotID={order.slot_id} orderDate={order.order_date} pagePagination={currentPage}
                                workingArea={order?.working_area_id}
                                pageCount={currentPage}
                                sort={sort}
                                status={statusData}
                                ordersCount={counts}
                                searchKey={searchValue}
                                bikerName={order.biker.user.first_name} /></td>
                            ) : null
                          ) : order.status !== "COMPLETED" && order.status !== "CANCELLED" && order.status !== "BIKER_ARRIVED" ? (
                            <td><NewByckerModal orderId={order.id} slotID={order.slot_id} orderDate={order.order_date} pagePagination={currentPage}
                              workingArea={order?.working_area_id}
                              pageCount={currentPage}
                              sort={sort}
                              ordersCount={counts}
                              status={statusData}
                              searchKey={searchValue}

                            /></td>
                          ) : null
                        }
                      </td>
                      <td>
                        {
                          order.status !== "COMPLETED" && order.status !== "CANCELLED" && order.status !== "BIKER_ARRIVED" && order.deleted_at === null ? (
                            <td>
                              <PastBooking orderId={order.id} slotId={order.slot_id} pagePagination={currentPage}
                                workingArea={order?.working_area_id}
                                pageCount={currentPage}
                                sort={sort}
                                ordersCount={counts}
                                status={statusData}
                                searchKey={searchValue}
                              /></td>

                          ) : null
                        }
                      </td>
                      <td>
                        {
                          order.status !== "COMPLETED" && order.status !== "CANCELLED" ? (
                            <td>
                              <CancelOrder orderId={order.id} pagePag={currentPage}
                                pageCount={currentPage}
                                sort={sort}
                                ordersCount={counts}
                                status={statusData}
                                searchKey={searchValue}
                                onSendData={handleChildData}
                              />
                            </td>


                          ) : null
                        }
                      </td>
                    </tr>
                  )
                })
              ) : (null)
            }
          </tbody>
        </Table>
      </div>

      {
        orders && statusData === "" && searchValue === "" ? (<Pagination onPress={getPage} countsPage={orders.ordersCount / 10} />) : null
      }


      {/* {
        data ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", height: "100vh", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <span className="loader"></span>
        </div>) : null
      } */}


    </div>
  )
}

export default BookingMenu

