import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { ASSIGN_BIKER, CANCEL_ORDER, DELETE_SUBS, GET_ALL_ORDERS_ADMIN, GET_ALL_ORDERS_STATUS, GET_ALL_SUBS, GET_AVAILABEL_BIKERS_TO_ASSIGN, UPDATE_DATE_SUBS, UPDATE_SCHUDLE } from "../Type";


const GetAllOrdersAdmin = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            // let res = await baseUrl.get(`v1/order/all-orders/admin?page=${page}&limit=10&sortByDate=${data.sort}`, config);
            // dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            if (data.status === "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=${page}&limit=10&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            } else if (data.status !== "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=${data.ordersCount}&status=${data.status}&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: e.data });
        }
    }
}
const GetAllOrdersAdminSort = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=${data.count}&sortByDate=${data.sort}`, config);
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: e.data });
        }
    }
}
const GetAllOrdersAdminStatus = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.status !== "" && data.sort === "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=${data.count}&status=${data.status}&sortByDate=DESC`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            } else if (data.status === "" && data.sort !== "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=${data.count}&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            } else if (data.status !== "" && data.sort !== "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=${data.count}&status=${data.status}&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: e.data });
        }
    }
}


const AssignBiker = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/order/assign-biker`, data, config);
            dispatch({ type: ASSIGN_BIKER, payload: res.data.data });
            console.log(res);
        } catch (e) {
            dispatch({ type: ASSIGN_BIKER, payload: e.data });
            console.log(e);
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
const AvailabelBikersAssign = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/biker/all-available?date=${data.date}&slot_id=${data.slotId}&order_id=${data?.order_id }`, config);
            dispatch({ type: GET_AVAILABEL_BIKERS_TO_ASSIGN, payload: res.data.data });
            // console.log(res);
        } catch (e) {
            dispatch({ type: GET_AVAILABEL_BIKERS_TO_ASSIGN, payload: e.data });
            // console.log(e);
        }
    }
}

//reschudle Cancel Order

const CancelOrderReschudle = (data) => {
    console.log(data.id)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/order/reschedule/${data.id}`, data, config);
            dispatch({ type: CANCEL_ORDER, payload: res.data.data });
            console.log(res);
        } catch (e) {
            dispatch({ type: CANCEL_ORDER, payload: e.data });
            console.log(e);
        }
    }
}

//All Suscriptions

const GetAllSubs = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            if (data.status === "" && (data.baqa === null || data.baqa === "")) {
                let res = await baseUrl.get(`v1/subscription/admin?page=${page}&limit=10&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            } else if (data.status !== "" && (data.baqa === null || data.baqa === "")) {
                let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=${data.count}&sortByDate=${data.sort}&status=${data.status}`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            } else if (data.status === "" && (data.baqa !== null || data.baqa !== "")) {
                let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=${data.count}&sortByDate=${data.sort}&package_id=${data.baqa}`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            } else if (data.status !== "" && (data.baqa !== null || data.baqa !== "")) {
                let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=${data.count}&sortByDate=${data.sort}&status=${data.status}&package_id=${data.baqa}`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SUBS, payload: e.data });
        }
    }
}

const DeleteSubData = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.delete(`v1/subscription/${data.id}`, config);
            dispatch({ type: DELETE_SUBS, payload: res.data.data });
            console.log(res)
        } catch (e) {
            dispatch({ type: DELETE_SUBS, payload: e.data });
            console.log(e)

        }
    }
}
const UpdateDateSubs = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.post(`v1/subscription/change-expire-date-subscription/${data.id}/${data.date}`, data, config);
            dispatch({ type: UPDATE_DATE_SUBS, payload: res.data.data });
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_DATE_SUBS, payload: e.data });
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }

        }
    }
}
const UpdateSchudle = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.put(`v1/order/change-date`, data, config);
            dispatch({ type: UPDATE_SCHUDLE, payload: res.data.data });
        } catch (e) {
            dispatch({ type: UPDATE_SCHUDLE, payload: e.data });
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }

        }
    }
}

const GetSpesificOrder = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.searchValue !== "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=${data.pageCount}&limit=${data.ordersCount}&searchByOrderNumber=${data.searchValue}&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            }else{
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=10&searchByOrderNumber=${data.searchValue}&sortByDate=${data.sort}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: e.data });
        }
    }
}


//All Orders Start
const GetSpesificOrderStart = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            let res = await baseUrl.get(`v1/order/all-orders/admin?page=${page}&limit=10&order_date=${data.date}`, config);
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: e.data });
            console.log(e);
        }
    }
}
//All Orders Start Data
const GetSpesificOrderStartData = (data) => {
    // console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.date !== "" && data.id === "") {
                let res = await baseUrl.get(`v1/order/all-orders/admin?page=1&limit=${data.limit}&order_date=${data.date}`, config);
                dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: res.data.data });
                // console.log(" التاريخ");
            }
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_ADMIN, payload: e.data });
            console.log(e);
        }
    }
}


const GetSpesificSubs = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if(data.searchValue !=="" ){
                let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=${data.ordersCount}&searchByInvoiceNumber=${data.searchvalue}&sortByDate=DESC`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SUBS, payload: e.data });
        }
    }
}
const GetSortSubs = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=10&sortByDate=${data.sortData}`, config);
            dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
        } catch (e) {
            dispatch({ type: GET_ALL_SUBS, payload: e.data });
        }
    }
}
const GetSortSubsStatus = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.status !== "") {
                let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=${data.count}&sortByDate=DESC&status=${data.status}`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            } else {
                let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=10&sortByDate=ASC`, config);
                dispatch({ type: GET_ALL_SUBS, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SUBS, payload: e.data });
        }
    }
}


const FilterByBaqa = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.get(`v1/subscription/admin?page=1&limit=${data.count}&sortByDate=DESC&package_id=${data.idPackage}`, config);
            dispatch({ type: GET_ALL_SUBS, payload: res.data.data });

        } catch (e) {
            dispatch({ type: GET_ALL_SUBS, payload: e.data });
        }
    }
}



//Get All Orders Status 
const OrderStatus = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.get(`v1/order/status`, config);
            dispatch({ type: GET_ALL_ORDERS_STATUS, payload: res.data.data });
            // console.log(res);
        } catch (e) {
            dispatch({ type: GET_ALL_ORDERS_STATUS, payload: e.data });
            console.log(e);
        }
    }
}


export { GetAllOrdersAdmin, OrderStatus, GetAllSubs, FilterByBaqa, AssignBiker, GetAllOrdersAdminSort, AvailabelBikersAssign, GetSpesificOrderStartData, CancelOrderReschudle, DeleteSubData, UpdateDateSubs, GetSpesificOrderStart, UpdateSchudle, GetSpesificOrder, GetAllOrdersAdminStatus, GetSpesificSubs, GetSortSubs, GetSortSubsStatus }