import React, { useEffect, useState } from 'react'
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import input from "../../Images/Input.png"
import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
import { DeleteSms, GetAllSms } from '../../redux/actions/Sms';
import { AddBikerToWorkingAreaId, DeleteBikersWorkingAtArea, GetBikersWorkingAtArea } from '../../redux/actions/AreaActions';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../Api/baseURL';

const AddBikerToWorkingArea = ({ id }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bikerSelect, setBikerSelect] = useState(null);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();


    const params = useParams();


    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // جلب التوكن من localStorage
        },
    };


    const [bikers, setBikers] = useState([]);

    const limit = 10; // عدد البايكرز لكل صفحة
    const sortByDate = 'ASC'; // الترتيب حسب التاريخ

    // دالة لجلب البيانات
    const fetchAllBikers = async () => {
        let allBikers = [];
        let currentPage = 1;
        let hasMore = true;

        setLoading(true);

        try {
            while (hasMore) {
                // استدعاء الـ API بالصفحة الحالية والحد الأقصى والفرز
                const response = await axios.get(
                    `${process.env.REACT_APP_BASEURL}v1/biker/all-bikers?page=${currentPage}&limit=${limit}&sortByDate=${sortByDate}`,
                    config
                );
                console.log(response)
                const newBikers = response?.data?.data?.bikers; // افترض أن البيانات في response.data.data

                if (newBikers.length > 0) {
                    allBikers = [...allBikers, ...newBikers];
                    currentPage++; // الانتقال للصفحة التالية
                } else {
                    hasMore = false; // وقف التحميل لو الصفحة فارغة
                }
            }
            setBikers(allBikers); // حفظ جميع البايكرز
        } catch (error) {
            console.error('Error fetching bikers:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // استدعاء الدالة لجلب كل البيانات دفعة واحدة
        fetchAllBikers();
        console.log("data")
    }, []);




    const addbiketToArea = async () => {
        try {
            await dispatch(AddBikerToWorkingAreaId(
                {
                    biker_id: bikerSelect,
                    working_area_id: params?.id
                }
            ))
            handleCloseEdit();
            setBikerSelect(null)
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(GetBikersWorkingAtArea({
                id: params?.id
            }))
        }
    }

    return (
        <div>
            <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={handleShowEdit}>اضافة بايكر للمنطقه</button>
            <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
                <Modal.Body className='text-center py-4'>
                    <p>اضافة بايكر الي المنطقه</p>
                    <select className='form-control w-100' onChange={(e) => setBikerSelect(e.target.value)} value={bikerSelect}>
                        <option hidden>اختر البايكر</option>
                        {bikers.map((biker) => (
                            <option key={biker.id} value={biker.id}>
                                {biker?.user?.first_name} {biker?.user?.last_name}
                            </option>
                        ))}
                    </select>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44 ", color: "white" }} onClick={addbiketToArea}>
                        تاكيد الاضافه
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddBikerToWorkingArea
